
            @import 'src/styles/mixins.scss';
        
.mainContainer {
    margin-top: 25px;
    margin-bottom: 60px;
    h1 {
        font-weight: bold;
        font-size: 20px;

        @include lg {
            font-size: 28px;
            margin-top: 50px;
        }
    }
    p {
        font-size: 16px;
        line-height: 30px;
        margin-top: 10px;
        font-weight: 300;
        @include lg {
            font-size: 18px;
            margin-top: 20px;
        }
    }
    h2 {
        font-size: 18;
        margin-top: 10px;
        @include lg {
            font-size: 24px;
            margin-top: 20px;
        }

        font-weight: bold;
    }
    h3 {
        font-size: 16;
        margin-top: 5px;
        @include lg {
            font-size: 20px;
            margin-top: 10px;
        }

        font-weight: bold;
    }
    a {
        color: #1693f0;
        &:hover {
            text-decoration: underline;
        }
    }
    .decimalStyleOnlyLi,
    [class="decimalStyleOnlyLi"] {
        li {
            font-size: 16px;
            line-height: 30px;
            margin-top: 10px;
            font-weight: 300;
            @include lg {
                font-size: 18px;
                margin-top: 20px;
            }
        }
    }
    .decimalStyle,
    .decimalStyleOnlyLi,
    [class="decimalStyle"],
    [class="decimalStyleOnlyLi"] {
        list-style-type: none;
        counter-reset: css-counter 0; /* initializes counter to 0; use -1 for zero-based numbering */
    }

    .decimalStyle li h2,
    .decimalStyleOnlyLi li,
    [class="decimalStyle"] li h2,
    [class="decimalStyleOnlyLi"] li {
        counter-increment: css-counter 1; /* Increase the counter by 1. */
    }

    .decimalStyle li h2:before,
    .decimalStyleOnlyLi li:before,
    [class="decimalStyle"] li h2:before,
    [class="decimalStyleOnlyLi"] li:before {
        content: counter(css-counter) ". "; /* Apply counter before children's content. */
    }
    .alphaStyle,
    [class="alphaStyle"] {
        list-style: lower-alpha;
        margin-left: 30px;
        @include lg {
            margin-left: 40px;
        }

        li {
            font-size: 16;

            @include lg {
                font-size: 20px;
            }

            font-weight: bold;
        }
    }
    address {
        margin-top: 20px;
        font-size: 18px;
    }
    ul {
        list-style: disc;
        margin-left: 30px;
        li {
            font-size: 16px;
            line-height: 30px;
            margin-top: 10px;
            font-weight: 300;
            @include lg {
                font-size: 18px;
                margin-top: 20px;
            }
        }
    }
    .timeStyle,
    [class="timeStyle"] {
        font-size: 13px;
        color: #999;
    }
}
.modalStyle {
    padding: 0 35px 20px 14px;
    @include lg {
        padding: 0 28px 20px;
    }
}
